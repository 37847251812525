import { FramesOnScroll } from '/components/buildingBlocks/collab/FramesOnScroll'
import { ScrollIndicator } from '/components/buildingBlocks/collab/ScrollIndicator'
import { ContainerLg } from '/components/buildingBlocks/Container'
import { StaggerHeading } from '/components/buildingBlocks/StaggerHeading'
import { HeadingPrimaryXxl, HeadingPrimaryMd } from '/components/buildingBlocks/Heading'
import { useChain, animated } from 'react-spring'
import { useStaggerAnimationsCollab } from '/machinery/useStaggerAnimationsCollab'
import { Logo } from '/components/buildingBlocks/Logo'
import { useMediaQuery } from '@kaliber/use-media-query'

import logoGroup from '/images/logos/circle-8-light-red.svg'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function Hero({ layoutClassName }) {
  const { refs, springs } = useStaggerAnimationsCollab({
    heading: 'Global Talent Matching Partner of the Aston Martin Aramco F1##®## Team'
  })

  useChain(refs, [0, 0.2], 100)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <FramesOnScroll layoutClassName={styles.framesLayout} />

      <ContainerLg layoutClassName={styles.containerLayout}>
        <div className={styles.content}>
          <div className={styles.text}>
            <LogoAsset spring={springs.logo} src={logoGroup} layoutClassName={styles.logoLayout} />
            <h1 className={styles.heading} aria-label={'Global Talent Matching Partner of the Aston Martin Aramco F1® Team'}>
              <CollabHeading springs={springs.springs}  />
            </h1>
          </div>
          <ScrollIndicator layoutClassName={styles.indicatorLayout} href='#introduction' />
        </div>
      </ContainerLg>

      <ContainerLg layoutClassName={styles.logoLayout}>
        <div className={styles.logoContainer}>
          <Logo layoutClassName={styles.logoComponentLayout} />
        </div>
      </ContainerLg>
    </div>
  )
}

function CollabHeading({ springs }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const TitleElement = isViewportMd ? HeadingPrimaryXxl : HeadingPrimaryMd
  return (
    <>
      <StaggerHeading
        Element={'span'}
        heading='Global Talent Matching Partner'
        layoutClassName={styles.headingLayout}
        springs={springs.slice(0, 4)}
        render={(heading, animation) => (
          <TitleElement h='strong' {...{ heading, animation }} />
        )}
      />
      <StaggerHeading
        Element={'span'}
        heading='of the Aston Martin Aramco F1##®## Team'
        layoutClassName={styles.headingLayout}
        springs={springs.slice(4, 11)}
        render={(heading, animation) => (
          <TitleElement h='strong' {...{ heading, animation }} />
        )}
      />
    </>
  )
}

function LogoAsset({ src, layoutClassName, spring }) {
  return <animated.img style={spring} className={layoutClassName} alt='' role='presentation' {...{ src }} />
}
