import { animated } from 'react-spring'

import styles from './StaggerHeading.css'

export function StaggerHeading({ Element, springs, heading, render, layoutClassName = undefined }) {
  const splitHeadingArray = heading.split(' ')

  return (
    <Element aria-label={heading} className={cx(styles.component, layoutClassName)}>
      {springs.map((animation, i) => (
        <animated.span key={i} aria-hidden='true' className={styles.clippingContainer}>
          {render(splitHeadingArray[i], animation)}
        </animated.span>
      ))}
    </Element>
  )
}
