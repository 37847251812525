import { useScroll, animated, config, useSpringValue, to, useSpring } from 'react-spring'
import { useWindowEventListener } from '/machinery/useWindowEventListener'
import { useElementSize } from '@kaliber/use-element-size'
import { useFrames } from '/machinery/useFrames'

import styles from './FramesOnScroll.css'

export function FramesOnScroll({ layoutClassName }) {
  const frames = useFrames()
  const { size: { height: elementHeight }, ref: elementRef } = useElementSize()
  const { scrollYProgress } = useScroll({ config: config.slow })
  const scrollHeight = useSpringValue(0)

  const { value: autoPlayOffset } = useSpring({
    from: { value: 0 },
    to: { value: Math.round(frames.length * 0.25) },
    config: config.slow
  })

  useWindowEventListener('resize', handleResize)

  return (
    <div ref={elementRef} className={cx(styles.component, layoutClassName)}>
      {frames.map((filename, index) => (
        <animated.picture
          key={index}
          aria-hidden='true'
          role='presentation'
          data-visible={handleCurrentIndex(index)}
          className={styles.frame}
        >
          <AVIF {...{ filename }} />
          <WebP {...{ filename }} />
          <Image minLazyLoadCount={Math.round(frames.length * 0.25)} {...{ index, filename }} />
        </animated.picture>
      ))}
    </div>
  )

  function handleCurrentIndex(index) {
    return to([autoPlayOffset, scrollYProgress, scrollHeight], (offset, progress, height) => {
      const frame = Math.ceil((progress / (elementHeight / height)) * (frames.length * 0.75) + offset)
      return index === (Math.max(frame, 0) ?? 0)
    })
  }

  function handleResize() {
    scrollHeight.set(document.documentElement.scrollHeight)
  }
}

function AVIF({ filename }) {
  return (
    <source
      className={styles.componentAVIF}
      srcSet={`/images/stills/avif/${filename}.avif`}
      type="image/avif"
    />
  )
}

function WebP({ filename }) {
  return (
    <source
      className={styles.componentWebP}
      srcSet={`/images/stills/webp/${filename}.webp`}
      type="image/webp"
    />
  )
}

function Image({ index, filename, minLazyLoadCount = 10 }) {
  return (
    <img
      decoding='sync'
      loading={index > minLazyLoadCount ? 'lazy' : 'eager'}
      className={styles.componentImage}
      src={`/images/stills/jpg/${filename}.jpg`}
      role='presentation'
      alt=''
    />
  )
}
