import { config, useSprings, useSpring, useSpringRef } from 'react-spring'

export function useStaggerAnimationsCollab({ heading }) {
  const springsRef = useSpringRef()
  const logoRef = useSpringRef()

  const logo = useSpring({
    ref: logoRef,
    config: config.slow,
    from: { y: '100%', opacity: 0 },
    to: { y: '0%', opacity: 1 },
  })

  const [springs] = useSprings(heading.split(' ').length, i => ({
    ref: springsRef,
    config: config.slow,
    from: { y: '100%', opacity: 0.1 },
    to: { y: '0%', opacity: 1 },
    delay: i * 35
  }))

  return {
    refs: [
      springsRef,
      logoRef
    ],
    springs: {
      springs,
      logo
    }
  }
}
