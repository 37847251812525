import { Icon } from '/components/buildingBlocks/Icon'

import chevronDown from '/images/icons/chevron-down.raw.svg'
import styles from './ScrollIndicator.css'

export function ScrollIndicator({ href, layoutClassName }) {
  return (
    <a
      aria-label='Scroll to introduction'
      className={cx(styles.component, layoutClassName)}
      {...{ href }}
    >
      <span className={cx(styles.arrow, styles.arrowOne)}><Icon icon={chevronDown} /></span>
      <span className={cx(styles.arrow, styles.arrowSecond)}><Icon icon={chevronDown} /></span>
      <span className={cx(styles.arrow, styles.arrowThree)}><Icon icon={chevronDown} /></span>
    </a>
  )
}
