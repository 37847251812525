import styles from './Container.css'

export function ContainerLg({ children, layoutClassName = undefined, ...rest }) {
  return (
    <ContainerBase
      className={cx(styles.componentLg, layoutClassName)}
      {...{ children }}
      {...rest}
    />
  )
}

export function ContainerMd({ children, layoutClassName = undefined, ...rest }) {
  return (
    <ContainerBase
      className={cx(styles.componentMd, layoutClassName)}
      {...{ children }}
      {...rest}
    />
  )
}

function ContainerBase({ className, children, ...rest }) {
  return (
    <div className={cx(styles.componentBase, className)} {...rest}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
